import React, { Component } from 'react';
import { FiX, FiMenu } from 'react-icons/fi';
import { Link } from 'gatsby';

class Header extends Component {
  state = {
    menuOpen: false,
  };

  constructor(props) {
    super(props);
    this.menuTrigger = this.menuTrigger.bind(this);
    this.CLoseMenuTrigger = this.CLoseMenuTrigger.bind(this);
  }

  menuTrigger() {
    this.setState({ menuOpen: !this.state.menuOpen });
  }

  CLoseMenuTrigger() {
    this.setState({ menuOpen: false });
  }

  render() {
    const { logo, color = 'default-color' } = this.props;
    let logoUrl;
    if (logo === 'light') {
      logoUrl = (
        <img
          src="/assets/images/logo/logo.svg"
          alt="App Universe logo"
          height={60}
        />
      );
    } else {
      logoUrl = <img src="/assets/images/logo/logo.svg" alt="Digital Agency" />;
    }

    return (
      <header
        className={`header-area formobile-menu header--transparent ${color}`}
      >
        <div
          className={`header-wrapper ${this.state.menuOpen ? 'menu-open' : ''}`}
          id="header-wrapper"
        >
          <div className="header-left">
            <div className="logo">
              <Link to="/">{logoUrl}</Link>
            </div>
          </div>
          <div className="header-right">
            <nav className="mainmenunav d-lg-block">
              <ul className="mainmenu">
                {/* <li>
                  <Link to="/portfolio">Kundcase</Link>
                </li> */}
                <li>
                  <Link to="/kontakt">Kontakt</Link>
                </li>
              </ul>
            </nav>
            {/* Start Humberger Menu  */}
            <div className="humberger-menu d-block d-lg-none pl--20">
              <span
                onClick={this.menuTrigger}
                className="menutrigger text-white"
              >
                <FiMenu />
              </span>
            </div>
            {/* End Humberger Menu  */}
            <div className="close-menu d-block d-lg-none">
              <span onClick={this.CLoseMenuTrigger} className="closeTrigger">
                <FiX />
              </span>
            </div>
          </div>
        </div>
      </header>
    );
  }
}
export default Header;
